<template>
  <div class="document">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <div class="form-item mg-b-0 form-half">
        <el-form-item
        >
          <v-checkbox-group
            v-model="formData.renew_supplementary"
            block
            :list="renew_supplementary"></v-checkbox-group>
        </el-form-item>
      </div>
      <div
        v-if="formData.renew_supplementary.includes(100)"
        class="form-item form-half mg-b-0">
        <el-form-item
          ref="other_renew_supplementary"
          prop="other_renew_supplementary"
        >
          <v-input
            v-model="formData.other_renew_supplementary"
            type="textarea"
            :rows="4">
          </v-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapState} from 'vuex'
export default {
  name: 'supplementary-document',
  components:{
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
  },
  data(){
    return{
      oldFormDataLock:-1,
      showUpload:false,
      other_upload:{},
      formData:{
        renew_supplementary: [],
        other_renew_supplementary:''
      }
    }
  },
  computed:{
    renew_supplementary(){
      let {renew_supplementary} = this.globalOptions
      return renew_supplementary||[]
    },
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if(this.formDataLock!==this.oldFormDataLock){
          let {renew_supplementary,other_renew_supplementary} = val||{}
          this.formData.renew_supplementary = renew_supplementary||[]
          this.formData.other_renew_supplementary = other_renew_supplementary
        }
      }
    },
  },
  methods:{
    submitForm(formName){
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          this.$emit('getFormData', formName, this.formData)
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.document{
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  .document-file{
    .flex{
      margin: 5px 0;
      align-items: center;
      span{
        font-weight: normal!important;
      }
      i{
        margin-right: 10px;
      }
    }
  }
  .hr-inline{
    margin: 15px 0;
  }
}
.other-upload-box{
  border-radius: 4px;
  background:  #F7FAFC;
  padding: 16px;
  margin-top: 8px;
  flex: 1;
  .other-file-name{
    color:  #2D3748;
    font-size: 14px;
    font-style: normal;
    font-weight: 700!important;
    line-height: 150%;
  }
  span{
    span{
      color: $primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: inline-block;
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
.driver-text-title{
  color:#000;
  /* Medium/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.dirver-line{
  border-top: 1px solid #CBD5E0;
  padding-top: 20px;
}
.flex-wrap{
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.mg-b-0{
  .el-form-item{
    margin-bottom: 0;
  }
}

</style>
