export default {
  computed:{
    remarkInit(){
      return '此單最低消費HK$____。\n' +
          '如發生意外，必須於七日內向保險公司申報。\n' +
          'Minimum spending for this policy is HK$____.\n' +
          'In case of accident, it is a must to report to insurance company within 7 days.'
    }
  }
}
